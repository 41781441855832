@media (min-width: 1025px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-topbar {
                left: 0;
            }

            .layout-main, .layout-footer {
                margin-left: 0;
            }

            &.layout-overlay-sidebar-active {
                .layout-sidebar {
                    left: 0;
                }

                .layout-topbar  {
                    left: 250px;
                }
            }

            &.layout-mobile-sidebar-active {
                .layout-sidebar {
                    left: 0;
                }

                .layout-topbar  {
                    left: 250px;
                }
            }
        }

        &.layout-static {
            .layout-topbar {
                left: 250px;
            }

            .layout-main, .layout-footer {
                margin-left: 250px;
            }

            &.layout-static-sidebar-inactive {
                .layout-topbar  {
                    left: 0;
                }

                .layout-main, .layout-footer {
                    margin-left: 0;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .layout-wrapper {
        .layout-topbar {
            left: 0;
        }

        .layout-main, .layout-footer {
            margin-left: 0;
        }

        .layout-sidebar {
            transition: left $transitionDuration;
            left: -250px;
            margin-top: 50px;
        }

        &.layout-static {
            .layout-topbar {
                left: 0;
            }

            .layout-main, .layout-footer {
                margin-left: 0;
            }

            &.layout-static-sidebar-inactive {
                .layout-topbar {
                    left: 0;
                }

                .layout-main, .layout-footer {
                    margin-left: 0;
                }
            }

            &.layout-mobile-sidebar-active {
                .layout-sidebar {
                    left: 0;
                }

                .layout-topbar {
                    left: 0;
                }
            }
        }
    }
    .body-overflow-hidden {
        overflow: hidden;
    }
}

.detallespedidosidebar,
.detallespedidoadmin,
.sidebardetallemispedidos{
    height: 100% !important;
    overflow: scroll;
}


@media (max-width: 576px) { 
    
    .verdetallepedidoactual{
        top: 0 !important;
        z-index: 1000 !important;    
    }

    .topmenos50{
        margin-top: -20px !important;
    }

    
    .loginform{
        width: 90% !important;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 40% !important;
    }

    .verescritorio{
        display: none !important;
    }

    .vermobile{
        display: block !important;
    }

    .sidebar-cliente{
        width: 100% !important;
    }

    .datoproducto{
        text-overflow: ellipsis !important;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left !important;
        font-size: 12px !important;
    }
    
    .pedido_dato{
        text-overflow: ellipsis !important;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis !important;
        white-space: nowrap;
        text-align: left !important;
        font-size: 12px !important;
    }
    .datoitemactual{
        /* text-overflow: ellipsis !important; */
        /* min-width: 100px !important; */
       /*  overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; */
        text-align: left;
        font-size: 12px !important;
    }
    .p-paginator.p-component.p-paginator-bottom{
        padding: 0px !important;
        font-size: 10px !important;
    }

    .camposbusqueda{
        padding: 3px !important;
    }

    .cantidadagregar{
        padding: 2px !important;
        button{
            width: 100%;
            text-align: center !important;
        }
    }

    .detalleproductomobile{
        padding: 0px !important;
    }

    .detallespedidoadmin{
        width: 100% !important;
    }

    .layout-main{
        padding: 15px !important;
        padding-top: 70px !important;
    }
    .verdetallepedidoactual2{
        position: fixed;
        z-index: 100 !important;
        height: 35px !important;
        right: 0px !important;
        font-size: 1em;
    }
    .logo-mobile{
        position: absolute;        
        top: 0;
        right: 0;
        padding: 10px !important;
    }
    .logo-mobile img{
        height: 30px;
    }
    .sidebardetallemispedidos{
        width: 100% !important;
    }

    .detallemispedidos td{
        text-align: left !important;
    }
    .letra12{
        font-size: 12px !important;
    }
    .botoneliminaritem{
        width: 25px !important;
        height: 25px !important;
    }    
 }
